









import LabSim from '@/tasks/components/simulations/LabSim.vue';
import LabTask from '@/tasks/components/dynamic-questions/labs/LabTask.vue';
import {CompletableSimMixin} from '@/tasks/mixins/completable-sim';
import {defineComponent, ref} from '@vue/composition-api';
import {useAuthService} from '@/auth/services/authService';
import {useInertia} from '@/inertia/plugins/inertia';

export default defineComponent({
  name: 'CalorimetryOttawaPartOneSIM',
  components: {LabSim, LabTask},
  mixins: [CompletableSimMixin()],

  setup() {
    // set this way because if the user has not already been got, it will return null
    let randomMetalSeed = ref<number | null>(useInertia().getSeed());

    return {randomMetalSeed};
  },
});
